import { useRouter } from 'next/router'
import React, { useContext, createContext, useEffect, useState } from 'react'

const historyManagerContext = createContext<ReturnType<typeof useHistoryManager> | null>(null)

export function HistoryManagerProvider({ value, children }: { value: any; children: any }) {
  return <historyManagerContext.Provider value={value}>{children}</historyManagerContext.Provider>
}

export const useHistoryContext = () => useContext(historyManagerContext)

export function useHistoryManager() {
  const router = useRouter()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setHistory((prevState) => [...prevState, url])
      }
    }

    router.beforePopState(() => {
      setHistory((prevState) => prevState.slice(0, -2))
      return true
    })

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return { history, canGoBack: () => history.length > 1 }
}

export function useGoBack(fallback_url: string) {
  const history_context = useHistoryContext()
  const router = useRouter()

  const onBack = React.useCallback(() => {
    if (history_context?.canGoBack()) {
      router.back()
    } else {
      router.push(fallback_url)
    }
  }, [fallback_url, history_context, router])

  return onBack
}
