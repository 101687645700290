import { Box } from '@/styles/system'
import styled from '@emotion/styled'

const IconJinka = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="27"
    fill="none"
    viewBox="0 0 19 27"
    className="jinka"
  >
    <path
      fill="url(#paint0_linear)"
      d="M8.605 0c-.839 0-1.639.323-2.234.924L1.013 6.242C-.202 7.45-.34 9.348.687 10.562a3.114 3.114 0 002.266 1.118 3.195 3.195 0 002.348-.908l5.523-5.488a4.334 4.334 0 013.073-1.265h.074L10.848.913A3.19 3.19 0 008.604 0z"
    />
    <path
      fill="url(#paint1_linear)"
      d="M5.524 10.023c.378-.744.301-1.721.91-4.853.567-2.92 1.928-4.048 3.572-4.85a3.196 3.196 0 00-1.4-.32c-.84 0-1.64.323-2.235.924L1.013 6.242C-.202 7.45-.34 9.348.687 10.562a3.114 3.114 0 002.266 1.118c.405.01.808-.05 1.183-.183.779-.675 1.163-1.032 1.388-1.474z"
    />
    <path
      fill="#33547B"
      className="J"
      d="M18.04 9.226c0-1.18-.802-1.943-1.488-2.629a2.805 2.805 0 00-1.98-.818c-.728 0-1.419.283-1.93.792l-1.927 1.914 1.872 1.86v6.828c0 .927-.36 1.79-1.022 2.446a3.475 3.475 0 01-5.652-1.099 2.738 2.738 0 00-2.523-1.654c-.352 0-.71.069-1.062.212-1.39.606-2.033 2.197-1.45 3.571 1.397 3.288 4.63 5.417 8.23 5.425a8.867 8.867 0 006.309-2.608 8.793 8.793 0 002.615-6.266l.008-7.974z"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="7.795"
        x2="6.339"
        y1="7.075"
        y2="6.148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CB6CCD" />
        <stop offset="1" stopColor="#B061B1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="8.907"
        x2="1.172"
        y1=".786"
        y2="10.867"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F098F2" />
        <stop offset=".417" stopColor="#F7C3F8" stopOpacity=".583" />
        <stop offset="1" stopColor="#BB56BD" />
      </linearGradient>
    </defs>
  </svg>
)

const IconHeart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" fill="none" viewBox="0 0 9 7">
    <path
      fill="#fff"
      d="M9 2.222C9 .995 7.915 0 6.577 0 5.695 0 4.924.432 4.5 1.077 4.076.432 3.305 0 2.423 0 1.085 0 0 .995 0 2.222c0 .639.294 1.214.765 1.62l3.468 3.043A.36.36 0 0 0 4.499 7a.36.36 0 0 0 .252-.1l3.485-3.06c.47-.405.764-.98.764-1.619Z"
    />
  </svg>
)

const Footer = styled.footer`
  grid-area: footer;
  background-color: #714072;
  color: var(--color-dark);
  height: 235px;
  color: white;

  .J {
    fill: white;
  }

  a {
    color: white;
  }

  .apps {
    background-color: #855c87;
  }

  @media screen and (min-width: 599px) {
    br {
      display: none;
    }
  }
`

const apple_store_link = 'https://apps.apple.com/fr/app/jinka/id1519258255'
const google_play_link =
  'https://play.google.com/store/apps/details?id=com.babelfrance.loueragile&referrer=utm_source%3Djinka_pro'

const ProtectedFooter = () => {
  return (
    <Footer>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mx="auto"
        py={30}
        px={20}
        height="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          flex={1}
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconJinka />
            <Box as="p" ml={10} className="fz-16" textAlign="left">
              Le portail qui vous redonne le pouvoir
            </Box>
          </Box>
          <Box mt="auto" textAlign="left" className="fz-14">
            <Box as="p">2016-2024 - Jinka - Tous droits réservés</Box>
            <Box as="p" display="flex" alignItems="center" justifyContent="flex-start">
              <Box as="span" mr={10}>
                Conçu et développé en France avec
              </Box>{' '}
              <IconHeart />
            </Box>
          </Box>
        </Box>
        <Box display="flex">
          <Box display="flex" alignItems="flex-start" flexDirection="column" ml={70}>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              // href="https://loueragile.zendesk.com/hc/fr/requests/new"
              href="mailto:contact@jinka.fr"
              mt={10}
            >
              Contact
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={15}
              href="https://www.welcometothejungle.com/fr/companies/jinka"
            >
              Recrutement
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={15}
              href="https://twitter.com/jinka_app"
            >
              Twitter
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={15}
              href="https://www.linkedin.com/in/marclebel/"
            >
              Linkedin
            </Box>
            <Box
              as="a"
              className="tdh"
              target="_blank"
              rel="noopener noreferrer"
              mt={15}
              href="https://www.instagram.com/jinka_app/"
            >
              Instagram
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          borderRadius={10}
          p={20}
          ml={60}
          className="apps"
        >
          <Box
            as="a"
            href={apple_store_link}
            target="_blank"
            rel="noopener noreferrer"
            textAlign="center"
          >
            <img
              src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Apple.png"
              style={{ height: 58 }}
              alt="Télécharger dans l'App Store"
            />
          </Box>
          <Box
            as="a"
            href={google_play_link}
            target="_blank"
            rel="noopener noreferrer"
            textAlign="center"
            mt={20}
          >
            <img
              src="https://res.cloudinary.com/loueragile/image/upload/v1601450698/web/jinka/Google.png"
              style={{ height: 58 }}
              alt="Disponible sur Google Play"
            />
          </Box>
        </Box>
      </Box>
    </Footer>
  )
}

export default ProtectedFooter
