import { css, Global, useTheme } from '@emotion/react'

import { Lato } from 'next/font/google'
import React from 'react'
const font = Lato({ weight: ['400', '700'], subsets: ['latin'] })

export const Styles_Main = () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        :root {
          --font-lato: ${font.style.fontFamily};
          ${Object.keys(theme.colors).map(
            (key) => `--color-${key}: ${theme.colors[key as keyof typeof theme.colors]};`
          )}
          --rt-opacity: 1 !important;
        }

        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        html {
          font-size: 62.5%;
          font-size: calc(1em * 0.625);
          font-family: sans-serif;
          text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
        }

        article,
        aside,
        dialog,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        main,
        nav,
        section {
          display: block;
        }

        body {
          margin: 0;
          font-family: var(--font-lato), 'Helvetica', 'Arial', sans-serif;
          font-display: optional;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: white;
          font-size: 1.6rem;
          color: var(--color-dark);
        }

        body {
          background-color: white;
        }

        [tabindex='-1']:focus {
          outline: none !important;
        }

        hr {
          box-sizing: content-box;
          height: 0;
          overflow: visible;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
        }

        p {
          margin: 0;
        }

        abbr[title],
        abbr[data-original-title] {
          text-decoration: underline;
          text-decoration: underline dotted;
          border-bottom: 0;
          cursor: help;
        }

        address {
          margin-bottom: 1rem;
          font-style: normal;
          line-height: inherit;
        }

        ol,
        ul,
        dl {
          padding: 0;
          margin: 0;
          list-style: none;
        }

        ol ol,
        ul ul,
        ol ul,
        ul ol {
          margin: 0;
        }

        dt {
          font-weight: 700;
        }

        dd {
          margin: 0;
        }

        blockquote {
          margin: 0;
        }

        dfn {
          font-style: italic;
        }

        b,
        strong {
          font-weight: bold;
        }

        small {
          font-size: 80%;
        }

        sub,
        sup {
          position: relative;
          font-size: 75%;
          line-height: 0;
          vertical-align: baseline;
        }

        sub {
          bottom: -0.25em;
        }

        sup {
          top: -0.5em;
        }

        a {
          text-decoration: none;
          background-color: transparent;
          -webkit-text-decoration-skip: objects;
        }

        a:hover {
          // text-decoration: underline;
        }

        a:not([href]):not([tabindex]) {
          color: inherit;
          text-decoration: none;
        }

        a:not([href]):not([tabindex]):focus,
        a:not([href]):not([tabindex]):hover {
          color: inherit;
          text-decoration: none;
        }

        a:not([href]):not([tabindex]):focus {
          outline: 0;
        }

        pre,
        code,
        kbd,
        samp {
          font-family: monospace, monospace;
          font-size: 1em;
        }

        pre {
          margin-top: 0;
          margin-bottom: 1rem;
          overflow: auto;
          -ms-overflow-style: scrollbar;
        }

        figure {
          margin: 0;
        }

        img,
        table,
        td,
        blockquote,
        code,
        pre,
        textarea,
        input,
        video,
        svg {
          max-width: 100%;
        }

        img {
          height: auto;
          vertical-align: middle;
          border-style: none;
        }

        svg:not(:root) {
          overflow: hidden;
        }

        a,
        area,
        button,
        [role='button'],
        input:not([type='range']),
        label,
        select,
        summary,
        textarea {
          touch-action: manipulation;
        }

        table {
          border-collapse: collapse;
        }

        caption {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          color: #868e96;
          text-align: left;
          caption-side: bottom;
        }

        th {
          text-align: inherit;
        }

        label {
          display: inline-block;
          margin-bottom: 1rem;
        }

        button {
          border: 0;
          border-radius: 0;
          cursor: pointer;
          background-color: transparent;
        }

        button:focus {
          // outline: 1px dotted;
          // outline: 5px auto -webkit-focus-ring-color;
          outline: none;
        }

        input,
        button,
        select,
        optgroup,
        textarea {
          margin: 0;
          padding: 0;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }

        button,
        input {
          overflow: visible;
          -webkit-appearance: none;
        }

        button {
          text-transform: none;
          -webkit-appearance: button;
        }

        select {
          text-transform: none;
          -webkit-appearance: none;
        }

        button,
        html [type='button'],
        [type='reset'],
        [type='submit'] {
          // -webkit-appearance: button;
        }

        button::-moz-focus-inner,
        [type='button']::-moz-focus-inner,
        [type='reset']::-moz-focus-inner,
        [type='submit']::-moz-focus-inner {
          padding: 0;
          border-style: none;
        }

        input[type='radio'],
        input[type='checkbox'] {
          box-sizing: border-box;
          padding: 0;
        }

        input[type='date'],
        input[type='time'],
        input[type='datetime-local'],
        input[type='month'] {
          -webkit-appearance: listbox;
        }

        textarea {
          overflow: auto;
          resize: vertical;
          resize: none;
          line-height: 2.2rem;
        }

        fieldset {
          min-width: 0;
          padding: 0;
          margin: 0;
          border: 0;
        }

        legend {
          display: block;
          width: 100%;
          max-width: 100%;
          padding: 0;
          margin-bottom: 0.5rem;
          font-size: 1.5rem;
          line-height: inherit;
          color: inherit;
          white-space: normal;
        }

        progress {
          vertical-align: baseline;
        }

        [type='number']::-webkit-inner-spin-button,
        [type='number']::-webkit-outer-spin-button {
          height: auto;
          -webkit-appearance: none;
        }

        [type='search'] {
          outline-offset: -2px;
          -webkit-appearance: none;
        }

        [type='search']::-webkit-search-cancel-button,
        [type='search']::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        ::-webkit-file-upload-button {
          font: inherit;
          -webkit-appearance: button;
        }

        output {
          display: inline-block;
        }

        summary {
          display: list-item;
        }

        template {
          display: none;
        }

        [hidden] {
          display: none !important;
        }

        .btn-action {
          background-color: var(--color-bg_thin);
          border: 1px solid var(--color-thin);
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;

          &.no-border {
            border: 0;
          }

          &:disabled {
            opacity: 0.7;
            background: var(--color-thin);
            cursor: inherit;
          }
        }

        .form-checkbox {
          display: block;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          font-size: 1.6rem;
          line-height: 2.2rem;
          user-select: none;

          .checkmark {
            position: absolute;
            top: 1px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: white;
            border: 1px solid var(--color-thin);
            border-radius: 5px;
          }

          .checkmark.variant-yellow {
            border-color: #e4b60f;
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          .checkmark:after {
            left: 6px;
            top: 3px;
            width: 6px;
            height: 11px;
            border: solid var(--color-secondary);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          .checkmark.variant-yellow:after {
            border-color: #e4b60f;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0 !important;
            width: 0 !important;
            padding: 0 !important;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          &.is-medium .checkmark:after {
            left: 7px;
            top: 3px;
            width: 9px;
            height: 14px;
            border-width: 0 3px 3px 0;
          }

          &.is-medium .checkmark {
            height: 25px;
            width: 25px;
          }
        }

        .form-radio {
          display: block;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          font-size: 1.6rem;
          line-height: 2.2rem;
          user-select: none;

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: white;
            border: 1px solid var(--color-thin);
            border-radius: 100%;
          }

          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          .checkmark:after {
            left: 5px;
            top: 5px;
            width: 8px;
            height: 8px;
            background-color: var(--color-secondary);
            border-radius: 100%;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          &.is-light .checkmark:after {
            background-color: var(--color-light);
          }
        }

        .form-select {
          border: 1px solid var(--color-thin);
          padding: 16px 15px;
          color: var(--color-dark);
          border-radius: 5px;
          background-color: white;
          width: 100%;
          font-size: 1.6rem;

          option:first-of-type {
            color: #cdc9c9;
          }

          ::placeholder,
          &.placeholder {
            color: #cdc9c9;
          }

          ::-ms-expand {
            display: none;
          }

          /* */

          &.placeholder option:not(:first-of-type) {
            color: var(--color-dark);
          }

          /* */

          &.w-auto {
            width: auto;
          }

          /* */

          &:focus {
            border: 1px solid #aaaaaa;
            outline: none;
          }

          &:disabled {
            opacity: 0.7;
            background: var(--color-thin);
            cursor: inherit;
          }
        }

        .form-select {
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAaCAMAAADCHv/YAAAAOVBMVEUAAADFxcXFxcXFxcXFxcXExMTExMTDw8PDw8PFxcXHx8fExMTFxcXExMTCwsLExMTGxsbDw8PExMTgEiVsAAAAEnRSTlMAIN9/z+8wEKBgX7+vcFCPb0BfZ1NNAAAAnklEQVQ4y43S2w4CIQwEUAq7sAp46f9/rMVoJmaFzjzxwGEIJQTpgU+XYCLqQYuk0UxVhXGF6vZuMVMYcaiOFjPbsIUXlgJDCRhZglJ/95SGE06Z3AStjqCNDFE/ArnPjcTJ7BIMJWBoAZP/ibT8Q3t2hGcyxNLcIHaIaS5quZ4Ea54QhOnfxcMHuA7qWNMgWIOn4yIRA+INBG3aVLwAyRAMhXF8WbsAAAAASUVORK5CYII=');
          background-size: 16.37px 8.56px;
          background-repeat: no-repeat;
          background-position: right 15px center;
          padding-right: 45px;
          cursor: pointer;

          &.is-tiny {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &.placeholder.no-placeholder {
            color: var(--color-dark);
          }
        }

        .form-textarea {
          border: 1px solid var(--color-thin);
          padding: 16px 15px;
          border-radius: 5px;
          color: var(--color-dark);
          width: 100%;
          font-size: 1.6rem;

          ::placeholder,
          &.placeholder {
            color: #cdc9c9;
          }

          ::-ms-expand {
            display: none;
          }

          /* */

          &.placeholder option:not(:first-of-type) {
            color: var(--color-dark);
          }

          /* */

          &.w-auto {
            width: auto;
          }

          /* */

          &:focus {
            border: 1px solid #aaaaaa;
            outline: none;
          }

          &:disabled {
            opacity: 0.7;
            background: var(--color-thin);
            cursor: inherit;
          }
        }

        .jinka-tooltip {
          background-color: white !important;
          color: var(--color-dark) !important;
          padding: 15px !important;
          border-radius: 5px !important;
          line-height: 24px;
          font-size: 16px !important;
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);

          ul {
            list-style: inside;
          }
        }
      `}
    />
  )
}
