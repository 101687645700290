import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import {
  typography,
  space,
  color,
  width,
  SpaceProps,
  TypographyProps,
  ColorProps,
  WidthProps,
  compose,
  BorderProps,
  border,
  layout,
  flexbox,
  LayoutProps,
  FlexboxProps,
  grid,
  GridProps,
  background,
  BackgroundProps,
  position,
  PositionProps,
  ShadowProps,
  shadow,
} from 'styled-system'

type HelpersProps = TypographyProps &
  SpaceProps &
  ColorProps &
  BorderProps &
  LayoutProps &
  FlexboxProps &
  GridProps &
  BackgroundProps &
  PositionProps &
  ShadowProps &
  WidthProps & { fontWeight?: 400 | 700 } & {
    href?: string
    target?: string
    htmlFor?: string
    accept?: string
    for?: string
    src?: string
    disabled?: boolean
    type?: string
    value?: any
    name?: string
    rows?: number
  }

const box_helpers = (props: HelpersProps) =>
  compose(
    typography,
    color,
    space,
    width,
    border,
    layout,
    flexbox,
    grid,
    background,
    position,
    shadow
  )({
    lineHeight: props.fontSize ? `${(Number(props.fontSize) || 16) * 1.5}px` : undefined,
    ...props,
  })

const shouldForwardProp = (prop: string) =>
  isPropValid(prop) && !['fontSize', 'color', 'fontWeight'].includes(prop)

export const Box = styled('div', {
  shouldForwardProp,
})(box_helpers)

export const Text = (props: Parameters<typeof Box>[0]) => (
  <Box as="span" fontSize={16} lineHeight="24px" fontWeight={400} {...props} />
)
