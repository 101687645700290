import useSWR from 'swr'
import { FetchError, fetcher } from './misc'
import { Api_Me_Response } from '@/pages/api/auth/me'

export const usePro_auth_me = () => {
  const { data, error, isLoading, mutate } = useSWR<Api_Me_Response, FetchError>(
    '/api/auth/me',
    fetcher
  )

  return {
    me: data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useProtected_me = () => {
  const auth_me = usePro_auth_me()

  return auth_me.me!
}
