import { Styles_Main } from '@/styles/main'
import theme from '@/styles/theme'
import { ThemeProvider } from '@emotion/react'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { Protected } from '@/component/Protected'
import Head from 'next/head'
import { HistoryManagerProvider, useHistoryManager } from '@/component/hook.cangoback'
import { Box, Text } from '@/styles/system'
import Link from 'next/link'
import React from 'react'
import { usePro_auth_me } from '@/component/hook.me'

type AppPropsWithAuth<T> = AppProps<T> & {
  Component: {
    requireAuth?: boolean
    noWarnLogo?: boolean
  }
}

const BoxWarnLogo = () => {
  const { me } = usePro_auth_me()
  const [need_warn_logo, set_need_warn_logo] = React.useState(false)

  if (!me) return null

  return (
    <>
      <img
        style={{
          width: 0,
          height: 0,
          visibility: 'hidden',
          position: 'absolute',
        }}
        src={me.logo}
        onError={() => {
          set_need_warn_logo(true)
        }}
        alt="logo"
      />
      {need_warn_logo ? (
        <Box
          bg="bg_secondary"
          border="1px solid"
          borderColor="secondary"
          borderRadius={10}
          p={20}
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          mb={40}
        >
          <Box>
            <img width={63} src="/icon/illu-bubble.svg" alt="minus" />
          </Box>
          <Box flex={1} ml={20}>
            <Text color="secondary" as="p">
              Vous n'avez pas encore ajouté le logo de votre agence. Afin d'améliorer votre
              visibilité sur l'application Jinka, veuillez ajouter votre logo ainsi que les
              informations relatives à votre agence.
            </Text>

            <Box
              as={Link}
              href="/account"
              bg="secondary"
              borderRadius="5px"
              color="white"
              px={16}
              py="6px"
              mt={10}
              display="inline-block"
            >
              Ajouter mon logo
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  )
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithAuth<{ session: Session }>) {
  const historyManager = useHistoryManager()

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const scrollToAnchor = () => {
        const $element = document.getElementById(window.location.hash.replace('#', ''))
        if ($element && $element.offsetTop) window.scrollTo(0, $element.offsetTop - 30)
      }
      setTimeout(scrollToAnchor, 600)
    }
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
        />
        {process.env.NODE_ENV === 'production' ? (
          <script defer data-domain="pro.jinka.fr" src="https://plausible.io/js/script.js"></script>
        ) : null}
      </Head>
      <HistoryManagerProvider value={historyManager}>
        <SessionProvider session={session}>
          <ThemeProvider theme={theme}>
            <Styles_Main />

            {Component.requireAuth ? (
              <Protected>
                {Component.noWarnLogo ? null : <BoxWarnLogo />}
                <Component {...pageProps} />
              </Protected>
            ) : (
              <Component {...pageProps} />
            )}
          </ThemeProvider>
        </SessionProvider>
      </HistoryManagerProvider>
    </>
  )
}
