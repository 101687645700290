import { Box, Text } from '@/styles/system'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/nextjs'
import { signIn } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import ProtectedFooter from './Protected.Footer'
import { ProtectedHeader } from './ProtectedHeader'
import { usePro_auth_me } from './hook.me'

const Wrapper = styled(Box)`
  display: grid;
  grid-template-areas:
    'header header'
    'aside main'
    'footer footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 333px auto;
  height: 100%;
  color: #5c5c5c;
  min-width: 768px;

  @media screen and (max-width: 1240px) {
    grid-template-columns: 250px auto;
  }

  @media screen and (max-width: 1023px) {
    grid-template-areas:
      'header header'
      'aside aside'
      'main main'
      'footer footer';

    .question {
      display: none;
    }
  }

  .link {
    color: #5a8fb6;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
  }

  .button {
    background-color: #b361b5;
    color: white;
    padding: 16px 30px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
  }

  .confirmation {
    background-color: #6ea668;
    color: white;
    padding: 16px 30px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }

  input,
  textarea {
    background-color: white;
    border: 1px solid #dddcdc;
    height: 52px;
    padding: 16px;
    border-radius: 5px;
    width: 100%;
    outline: none;
    color: #5c5c5c;

    &::placeholder {
      color: #cdc9c9;
    }
  }

  textarea {
    height: auto;
  }

  input.btn-submit {
    background-color: var(--color-secondary);
    color: white;
    padding: 16px 30px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    width: auto;
    border: 0;

    &:disabled,
    &:disabled:hover {
      opacity: 0.3;
      cursor: auto;
    }
  }

  .fz-18 {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

  .fz-14 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .h1-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2.4rem;
    margin-bottom: 20px;

    .title {
      display: flex;
      align-items: center;
    }

    .number {
      background-color: #f1ddf1;
      color: #b361b5;
      font-size: 1.4rem;
      padding: 5px;
      border-radius: 5px;
      margin-left: 10px;

      &.negative {
        background-color: #fbd8d8;
        color: var(--color-negative);
      }
    }

    .input-ref {
      border: 1px solid #dddcdc;
      border-radius: 10px;
      height: 36px;
      width: 153px;
      font-size: 1.4rem;
      padding: 0 15px;
      color: #5c5c5c;
    }
  }

  .h2-title {
    font-size: 2rem;
    line-height: 2.6rem;

    .number {
      background-color: #f1ddf1;
      color: #b361b5;
      font-size: 1.4rem;
      padding: 5px;
      border-radius: 5px;
      margin-left: 10px;
    }
  }

  .h3-title {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
`

const Aside = styled(Box)`
  grid-area: aside;
  min-height: calc(100vh - 90px);
  border-right: 1px solid var(--color-thin);

  @media screen and (max-width: 1023px) {
    min-height: auto;
  }

  @media screen and (max-width: 1023px) {
    border-right: none;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
  }
`

const Main = styled(Box)`
  grid-area: main;
  padding: 40px;
  padding-bottom: 150px;
  max-width: 1107px;
`

const Nav = styled(Box)`
  font-size: 2rem;
  font-weight: bold;
  padding: 45px;

  a {
    color: #5c5c5c;
  }

  .active {
    a {
      color: #b361b5;
    }

    .icon {
      background-color: #b361b5;
      border: 1px solid #b361b5;
    }
  }

  li {
    display: flex;
    align-items: center;
  }

  li + li {
    margin-top: 30px;
  }

  hr {
    max-width: 176px;
    margin: 25px 0;
    border: none;
    border-top: 1px solid var(--color-thin);
  }

  .icon {
    width: 20px;
    height: 20px;
    border: 1px solid #919191;
    border-radius: 5px;
    margin-right: 15px;
    display: inline-block;
  }

  @media screen and (max-width: 1023px) {
    border-bottom: 1px solid #dddcdc;
    font-size: 1.8rem;
    padding: 20px 45px;
    flex: 1;

    ul {
      display: flex;
      align-items: center;
      overflow-x: scroll;
    }

    li + li {
      margin-top: 0;
      margin-left: 20px;
    }

    .icon {
      display: none;
    }
  }
`

export const Protected = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const { isError, isLoading, me } = usePro_auth_me()

  React.useEffect(() => {
    if (isError?.status === 401) {
      signIn()
    }
  }, [isError])

  React.useEffect(() => {
    if (me) {
      Sentry.setUser({ email: me.email, id: me.id })
    } else {
      Sentry.setUser(null)
    }
  }, [me])

  const location_name = router.asPath
  React.useEffect(() => {
    if (me) {
      fetch('/api/log', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ev: location_name,
        }),
      })
    }
  }, [me, location_name])

  if (isLoading || !me) {
    return <Box p={40}>Chargement...</Box>
  }

  if (isError) {
    if (isError.status === 401) {
      return <Box p={40}>Chargement...</Box>
    }

    return <>Oops Une erreur est survenue, merci de reessayer ulterieurement</>
  }

  const message_is_active =
    router.pathname === '/message' &&
    (!router.query.message_type || router.query.message_type === 'request')

  return (
    <Wrapper className="wrapper" margin="0 auto">
      <ProtectedHeader me={me} />

      <Aside className="aside" color="secondary">
        <Nav>
          {me.has_manual_deposit ? (
            <Box
              as={Link}
              href="/annonce/ajout"
              mb={30}
              borderWidth={1}
              borderColor="tertiary"
              borderStyle="solid"
              borderRadius={10}
              py={10}
              px={15}
              display="inline-block"
            >
              <Text color="tertiary" fontSize={20}>
                Ajouter une annonce
              </Text>
            </Box>
          ) : null}

          {me.abo_limit.limit ? (
            <Box
              bg="bg_thin"
              borderWidth={1}
              borderColor="thin"
              borderStyle="solid"
              borderRadius={10}
              p={20}
              color="dark"
              mb={40}
              maxWidth={213}
            >
              <Box>
                <Text display="block" lineHeight="16px">
                  Votre abonnement :{' '}
                </Text>
                {me.abo_limit.limit === -1 ? (
                  <Text color="negative" fontWeight={700}>
                    Aucun
                  </Text>
                ) : me.abo_limit.limit < 100000 ? (
                  <Text fontWeight={700}>{me.abo_limit.limit} annonces</Text>
                ) : (
                  <Text fontWeight={700}>Illimité</Text>
                )}
              </Box>

              {me.abo_limit.limit < 100000 ? (
                <Box mt={10}>
                  <a
                    href={`mailto:pro@jinka.fr?subject=${encodeURIComponent(
                      "Faire évoluer l'offre"
                    )}`}
                  >
                    <Text
                      color="green"
                      fontWeight={700}
                      style={{ textDecorationLine: 'underline' }}
                    >
                      Faire évoluer l'offre
                    </Text>
                  </a>
                </Box>
              ) : null}

              <Box height={2} bg="thin" my={20}></Box>

              <Box>
                <Text fontSize={16} fontWeight={700}>
                  Détail des annonces
                </Text>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={10}>
                  <Text>En ligne</Text>
                  <Box
                    px="5px"
                    borderRadius={5}
                    borderWidth={1}
                    borderColor="thin"
                    bg="white"
                    borderStyle="solid"
                    height={24}
                    display="flex"
                    alignItems="center"
                  >
                    <Text fontSize={14} fontWeight={700}>
                      {me.abo_limit.current}
                    </Text>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={10}>
                  <Text>Hors ligne</Text>
                  <Box
                    px="5px"
                    borderRadius={5}
                    borderWidth={1}
                    borderColor="thin"
                    bg="white"
                    borderStyle="solid"
                    height={24}
                    display="flex"
                    alignItems="center"
                  >
                    <Text fontSize={14} fontWeight={700}>
                      {me.abo_limit.offline}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}

          <ul>
            <li className={router.pathname === '/annonce' ? 'active' : ''}>
              <Box>
                <Box display="flex" alignItems="center">
                  <span className="icon"></span>
                  <Link href="/annonce">Annonces</Link>
                </Box>
                <Box as="ul" mt={10}>
                  <li>
                    <Box
                      width={13}
                      height={13}
                      border="1px solid"
                      borderColor="light"
                      borderTopWidth={0}
                      borderRightWidth={0}
                      display="inline-block"
                      margin="6px 16px 9px 4px"
                    />
                    <Box as={Link} href="/archive">
                      <Text color={router.pathname === '/archive' ? 'secondary' : 'dark'}>
                        Archives
                      </Text>
                    </Box>
                  </li>
                </Box>
              </Box>
            </li>

            <hr />

            <li className={message_is_active ? 'active' : ''}>
              <span className="icon"></span>
              <Link href="/message?message_type=request">Messages</Link>
            </li>
            <li className={router.pathname === '/call' ? 'active' : ''}>
              <span className="icon"></span>
              <Link href="/call">Appels</Link>
            </li>
            <li
              className={
                router.pathname === '/message' && router.query.message_type === 'matching'
                  ? 'active'
                  : ''
              }
            >
              <span className="icon"></span>
              <Link href="/message?message_type=matching">Matchings</Link>
            </li>

            <hr />

            <li className={router.pathname === '/statistiques' ? 'active' : ''}>
              <span className="icon"></span>
              <Link href="/statistiques">Statistiques</Link>
            </li>
          </ul>
        </Nav>
        <Box display="inline-block" className="question" mb={4}>
          <Box
            ml={45}
            border="1px solid"
            borderColor="thin"
            borderRadius={10}
            color="dark"
            display="flex"
            fontSize={16}
            alignItems="center"
            px={15}
            py={10}
            as={Link}
            href="/faq"
          >
            <img src="/icon/icon-question.svg" alt="icon-mailbox" />
            <Box ml={15}>
              <Box lineHeight="auto">Une question ?</Box>
              <Box
                as="strong"
                fontSize={18}
                lineHeight="auto"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt="-3px"
              >
                <span>FAQ</span>
                <img src="/icon/icon-question-arrow.svg" alt="icon-mailbox" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Aside>
      <Main className="main">{children}</Main>

      <ProtectedFooter />
    </Wrapper>
  )
}
